.milestones-graph {
  margin-top: 1em;
  padding-right: 1rem;
  display: flex;
  flex-direction: row;
  position: relative;
  --bar-height: 2rem;
}

.milestones-graph .labels {
  padding: 0 1rem;
  min-width: var(--labels-width);
  max-width: var(--labels-width);
  width: var(--labels-width);
  text-align: right;
}
.milestones-graph .bar,
.milestones-graph .label {
  height: var(--bar-height);
  margin-top: 1rem;
}

.milestones-graph .bars {
  width: 100%;
}

.milestones-graph .label {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
