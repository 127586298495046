:root {
  --color: white;
  --font-color: white;
  --bg: #000;
  --tile-bg: #222223;
  --header-bg: #3f51b5;
  --light-grey: #666;
  font-family: pragmatica, sans-serif;
  font-style: normal;
  font-weight: 200;
}

body {
  font-family: pragmatica, sans-serif;
  font-style: normal;
  font-weight: 200;
}

code {
  font-family: pragmatica, sans-serif;
  font-style: normal;
  font-weight: 200;
}
